body {
  background-color: #cba259;
  color: white;
}

a {
  color: rgba(131, 143, 255, 0.774);
}

a:hover {
  color: rgba(131, 143, 255, 0.774);
}

p {
  line-height: 18px;
}

.login-box {
  max-width: 900px;
  padding: 20px;
  background-color: #183232;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .form-check {
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  border: 2px solid #cba259;
} */

label {
  margin-bottom: 0px;
}

.btn {
  margin-top: 20px;
}

.btn-primary,
.btn-primary:visited,
.btn-primary:focus,
.btn-primary:active {
  background-color: #cba259 !important;
  border-color: #cba259 !important;
}
.btn-primary:hover {
  background-color: #aa884b !important;
  border-color: #aa884b !important;
}

@media (max-width: 575.98px) {
  .image-component {
    padding-bottom: 20px;
    border-bottom: 1px solid white;
  }

  .form-component {
    padding-top: 20px;
  }
}

@media (min-width: 576px) {
  body {
    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .image-component {
    border-right: 1px solid white;
    padding-right: 30px;
  }

  .form-component {
    padding-left: 30px;
  }
}

